<template>
  <div id="app">
    <div class="flex flex-col min-h-screen text-gray-850">
      <header class="pt-8 mb-8">
        <div class="container">
          <div class="flex items-center justify-between">
            <a href="#">
              <img src="@/assets/images/logo.svg" class="w-48" alt="Rubicon" />
            </a>

            <ul class="inline-flex items-center space-x-20 text-sm">
              <li class="hidden lg:block">
                <a
                  :href="`tel:${formatPhone}`"
                  class="inline-flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 mr-2 text-blue-850"
                    viewBox="0 0 22 22"
                  >
                    <path
                      d="M21.994 17.751c0 .583.018 1.167 0 1.75a2.535 2.535 0 01-1.083 2 2.427 2.427 0 01-1.4.493 4.477 4.477 0 01-.881-.065c-.774-.133-1.55-.26-2.314-.439a20.376 20.376 0 01-4.885-1.853 21.325 21.325 0 01-6.817-5.615A21.571 21.571 0 01.559 5.901a20.493 20.493 0 01-.494-2.614 3.578 3.578 0 01-.022-1.095A2.637 2.637 0 012.734-.002c.981 0 1.963.007 2.944 0a2.687 2.687 0 011.194.21 2.557 2.557 0 011.553 2 18.843 18.843 0 00.756 3.017 2.5 2.5 0 01-.589 2.618c-.318.345-.66.669-1 1a.131.131 0 00-.022.2 16.278 16.278 0 004.651 4.886c.24.166.489.317.731.48a.125.125 0 00.192-.021c.3-.307.605-.606.906-.91a2.589 2.589 0 012.161-.792 3.1 3.1 0 01.757.182 13.946 13.946 0 002.769.676 2.635 2.635 0 012.229 2.154 3.171 3.171 0 01.036.52v1.534zM6.148 8.742a1.2 1.2 0 00.1-.079q.74-.738 1.479-1.479a1.873 1.873 0 00.227-.268 1.564 1.564 0 00.156-1.5 12.593 12.593 0 01-.723-2.9 1.593 1.593 0 00-1.592-1.444H2.658a1.7 1.7 0 00-.542.1 1.576 1.576 0 00-1.021 1.7c.1.749.21 1.5.379 2.233a20.251 20.251 0 002.356 5.881A20.6 20.6 0 0016.789 20.5c.755.178 1.519.3 2.287.4a1.577 1.577 0 001.419-.465 1.6 1.6 0 00.436-1.17v-2.774c0-.262 0-.262.261-.284 0 0 .005 0 .021-.013h-.114c-.17-.006-.17-.006-.179-.177a1.534 1.534 0 00-1.1-1.354c-.307-.075-.625-.11-.936-.168a15.868 15.868 0 01-2.346-.643 1.553 1.553 0 00-1.729.4c-.487.489-.978.973-1.461 1.464a.163.163 0 01-.239.03 18.279 18.279 0 01-3.37-2.419 17.544 17.544 0 01-3.593-4.586z"
                      fill="currentColor"
                    />
                  </svg>
                  <span class="font-medium">{{ settings.phone }}</span>
                </a>
              </li>
              <li class="hidden lg:block">
                <a
                  :href="`mailto:${settings.email}`"
                  class="inline-flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 mr-2 text-blue-850"
                    viewBox="0 0 22 22"
                  >
                    <path
                      d="M10.981 18.996c-2.513 0-5.027-.008-7.541 0a2.41 2.41 0 01-2.312-1.618 2.061 2.061 0 01-.119-.632c-.011-1.515-.008-3.029-.008-4.544v-6.79a2.313 2.313 0 01.682-1.7 2.37 2.37 0 011.653-.713h15.195a2.435 2.435 0 011.351.371 2.266 2.266 0 011.11 1.955c.016.875 0 1.751 0 2.627v8.6a2.364 2.364 0 01-.66 1.7 2.27 2.27 0 01-1.394.71 3.781 3.781 0 01-.473.029q-3.74.007-7.484.005zm9.033-13c-.06.039-.1.062-.134.088q-4.389 3.029-8.777 6.061a.155.155 0 01-.212 0q-4.378-3.027-8.759-6.05c-.044-.03-.089-.057-.149-.1v2.731q0 3.982.006 7.965a1.2 1.2 0 00.117.5 1.412 1.412 0 001.373.824h15.04a2 2 0 00.3-.016 1.432 1.432 0 001.2-1.417c-.011-3.486-.005-6.972-.005-10.458zm-9.017-2.01H3.373a1.389 1.389 0 00-1.242.752c-.051.085-.046.136.044.2q4.359 3 8.713 6.009a.165.165 0 00.224 0q4.341-3 8.686-5.993c.136-.094.139-.1.05-.24a1.41 1.41 0 00-1.279-.726l-7.572-.006z"
                      fill="currentColor"
                    />
                  </svg>
                  <span>{{ settings.email }}</span>
                </a>
              </li>
              <li class="inline-flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 mr-2 text-blue-850"
                  viewBox="0 0 22 22"
                >
                  <path
                    d="M.999 10.994a10 10 0 119.994 10.005A10 10 0 01.999 10.994zm13.3.495H7.84a.387.387 0 01-.041 0c-.068-.007-.093.021-.083.09.014.1.024.2.032.308a11.442 11.442 0 00.344 2.028 13.278 13.278 0 002.547 5.063c.116.144.238.282.368.437a13.277 13.277 0 003.29-7.926zm-6.592-.99h6.591a13.272 13.272 0 00-3.3-7.917 13.293 13.293 0 00-3.293 7.917zm4.118 9.47a.857.857 0 00.126 0c.449-.081.9-.136 1.345-.251a9.007 9.007 0 006.492-6.685c.108-.477.149-.969.221-1.455.012-.079-.031-.09-.1-.09H15.395c-.09 0-.125.02-.129.118-.011.223-.036.445-.058.667a12.148 12.148 0 01-.34 1.883 14.219 14.219 0 01-2.692 5.381c-.111.138-.225.275-.353.433zm.028-17.953l-.014.03c.164.2.332.4.491.61a14.218 14.218 0 012.433 4.8 13.288 13.288 0 01.506 2.958c.005.092.052.094.12.094h4.5c.036 0 .072 0 .122-.008a9.055 9.055 0 00-8.162-8.482zm-9.84 9.479a8.95 8.95 0 005.527 7.83 8.257 8.257 0 002.622.636 14.765 14.765 0 01-2.41-3.953 13.993 13.993 0 01-1.025-4.513zm8.161-9.458a8.75 8.75 0 00-3.455 1.025 8.9 8.9 0 00-3.883 4.11 8.69 8.69 0 00-.835 3.329h4.722a14.236 14.236 0 013.449-8.468z"
                    fill="currentColor"
                  />
                </svg>
                <div class="inline-flex items-center space-x-1">
                  <a
                    @click.prevent="changeLang('ru')"
                    href="#"
                    class="text-blue-850"
                    :class="lang === 'ru' ? 'font-semibold' : 'text-opacity-50'"
                  >
                    RU
                  </a>
                  <span class="text-gray-350">|</span>
                  <a
                    @click.prevent="changeLang('en')"
                    href="#"
                    class="text-blue-850"
                    :class="lang === 'en' ? 'font-semibold' : 'text-opacity-50'"
                  >
                    EN
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <main class="flex flex-col justify-center flex-1 max-w-md mx-auto">
        <div class="container">
          <div class="mb-12">
            <img
              src="@/assets/images/logo-letter.svg"
              class="mx-auto animate-rotate-y"
              alt="Rubicon"
            />
          </div>

          <div class="mb-8 text-center lg:mb-16">
            <h1 class="mb-4 text-xl font-semibold">
              {{ translate.title }}
            </h1>

            <p v-for="(subtitle, index) in translate.subtitles" :key="index">
              {{ subtitle }}
            </p>

            <div class="mt-8 lg:mt-16">
              <div class="w-8 h-px mx-auto mb-2 bg-blue-850"></div>
              <p class="text-sm text-blue-850 text-opacity-70">
                {{ translate.signature }}
              </p>
            </div>
          </div>

          <ul
            class="flex flex-col items-center mb-4 space-y-2 lg:space-y-0 lg:space-x-20 lg:flex-row"
          >
            <li>
              <a :href="`tel:${formatPhone}`" class="inline-flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 mr-2 text-blue-850"
                  viewBox="0 0 22 22"
                >
                  <path
                    d="M21.994 17.751c0 .583.018 1.167 0 1.75a2.535 2.535 0 01-1.083 2 2.427 2.427 0 01-1.4.493 4.477 4.477 0 01-.881-.065c-.774-.133-1.55-.26-2.314-.439a20.376 20.376 0 01-4.885-1.853 21.325 21.325 0 01-6.817-5.615A21.571 21.571 0 01.559 5.901a20.493 20.493 0 01-.494-2.614 3.578 3.578 0 01-.022-1.095A2.637 2.637 0 012.734-.002c.981 0 1.963.007 2.944 0a2.687 2.687 0 011.194.21 2.557 2.557 0 011.553 2 18.843 18.843 0 00.756 3.017 2.5 2.5 0 01-.589 2.618c-.318.345-.66.669-1 1a.131.131 0 00-.022.2 16.278 16.278 0 004.651 4.886c.24.166.489.317.731.48a.125.125 0 00.192-.021c.3-.307.605-.606.906-.91a2.589 2.589 0 012.161-.792 3.1 3.1 0 01.757.182 13.946 13.946 0 002.769.676 2.635 2.635 0 012.229 2.154 3.171 3.171 0 01.036.52v1.534zM6.148 8.742a1.2 1.2 0 00.1-.079q.74-.738 1.479-1.479a1.873 1.873 0 00.227-.268 1.564 1.564 0 00.156-1.5 12.593 12.593 0 01-.723-2.9 1.593 1.593 0 00-1.592-1.444H2.658a1.7 1.7 0 00-.542.1 1.576 1.576 0 00-1.021 1.7c.1.749.21 1.5.379 2.233a20.251 20.251 0 002.356 5.881A20.6 20.6 0 0016.789 20.5c.755.178 1.519.3 2.287.4a1.577 1.577 0 001.419-.465 1.6 1.6 0 00.436-1.17v-2.774c0-.262 0-.262.261-.284 0 0 .005 0 .021-.013h-.114c-.17-.006-.17-.006-.179-.177a1.534 1.534 0 00-1.1-1.354c-.307-.075-.625-.11-.936-.168a15.868 15.868 0 01-2.346-.643 1.553 1.553 0 00-1.729.4c-.487.489-.978.973-1.461 1.464a.163.163 0 01-.239.03 18.279 18.279 0 01-3.37-2.419 17.544 17.544 0 01-3.593-4.586z"
                    fill="currentColor"
                  />
                </svg>
                <span class="font-semibold">{{ settings.phone }}</span>
              </a>
            </li>
            <li>
              <a
                :href="`mailto:${settings.email}`"
                class="inline-flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 mr-2 text-blue-850"
                  viewBox="0 0 22 22"
                >
                  <path
                    d="M10.981 18.996c-2.513 0-5.027-.008-7.541 0a2.41 2.41 0 01-2.312-1.618 2.061 2.061 0 01-.119-.632c-.011-1.515-.008-3.029-.008-4.544v-6.79a2.313 2.313 0 01.682-1.7 2.37 2.37 0 011.653-.713h15.195a2.435 2.435 0 011.351.371 2.266 2.266 0 011.11 1.955c.016.875 0 1.751 0 2.627v8.6a2.364 2.364 0 01-.66 1.7 2.27 2.27 0 01-1.394.71 3.781 3.781 0 01-.473.029q-3.74.007-7.484.005zm9.033-13c-.06.039-.1.062-.134.088q-4.389 3.029-8.777 6.061a.155.155 0 01-.212 0q-4.378-3.027-8.759-6.05c-.044-.03-.089-.057-.149-.1v2.731q0 3.982.006 7.965a1.2 1.2 0 00.117.5 1.412 1.412 0 001.373.824h15.04a2 2 0 00.3-.016 1.432 1.432 0 001.2-1.417c-.011-3.486-.005-6.972-.005-10.458zm-9.017-2.01H3.373a1.389 1.389 0 00-1.242.752c-.051.085-.046.136.044.2q4.359 3 8.713 6.009a.165.165 0 00.224 0q4.341-3 8.686-5.993c.136-.094.139-.1.05-.24a1.41 1.41 0 00-1.279-.726l-7.572-.006z"
                    fill="currentColor"
                  />
                </svg>
                <span class="text-sm">{{ settings.email }}</span>
              </a>
            </li>
          </ul>
        </div>
      </main>

      <footer class="mb-4">
        <div class="container">
          <div class="max-w-sm mx-auto">
            <p
              class="mb-3 text-sm font-light text-center"
              v-html="translate.address"
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 7 21"
              class="w-6 h-6 mx-auto animate-bounce text-blue-850"
            >
              <path
                d="M2.828 20.329L0 17.5l.707-.707 2.329 2.329V0h1v19.123l2.329-2.329.707.707-3.536 3.536z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </footer>
    </div>

    <div class="mb-24">
      <div class="container">
        <div class="bg-gray-100 h-80 md:h-120 lg:h-160">
          <yandex-map
            :coords="map.coords"
            :controls="map.controls"
            :zoom="map.zoom"
            class="h-full"
          >
            <ymap-marker
              marker-id="1"
              :coords="map.coords"
              :icon="map.markerIcon"
            />
          </yandex-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from "@/data/settings.json";
import { yandexMap, ymapMarker } from "vue-yandex-maps";

const LS_LANG_KEY = "rubicon-lang";

export default {
  name: "App",

  components: { yandexMap, ymapMarker },

  data: () => ({
    settings,

    lang: "en",
    map: {
      coords: [51.376665072469024, 128.12917049999993],
      controls: [],
      zoom: 13,
      markerIcon: {
        layout: "default#imageWithContent",
        imageHref: "./map-marker.svg",
        imageSize: [44, 52],
        imageOffset: [-22, -52],
      },
    },
  }),

  computed: {
    translate() {
      return this.settings.lang[this.lang];
    },

    formatPhone() {
      return this.settings.phone.replace(/[()\s]/gi, "");
    },
  },

  methods: {
    changeLang(lang) {
      this.lang = lang;
      localStorage.setItem(LS_LANG_KEY, lang);
    },
  },

  mounted() {
    if (localStorage.getItem(LS_LANG_KEY)) {
      this.lang = localStorage.getItem(LS_LANG_KEY);
    } else {
      localStorage.setItem(LS_LANG_KEY, this.lang);
    }
  },
};
</script>

<style lang="scss">
[class*="ymaps-2"][class*="-ground-pane"] {
  filter: grayscale(100%);
}
</style>
